import useReferrerCookie from "./use-referrer-cookie"

/**
 * Adds specified cookies to a link as query params
 * @param {string} link the link to append query params to
 * @param {Array<string>} cookies a list of cookies
 * @returns {string} the modified url
 */
const useCookieLinkQueryParam = (link, cookies) => {
  const url = new URL(link)
  const search = url.searchParams

  cookies.forEach(cookie => {
    search.set(cookie, useReferrerCookie(cookie))
  })

  url.search = search.toString()

  return url.toString()
}

export default useCookieLinkQueryParam
