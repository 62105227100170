import React from "react"
import Img from "gatsby-image"
import ColorizeFilter from "../ColorizeFilter"
import styles from "./process.module.scss"

/**
 * Draws "steps" of images, headers, and descriptions, separated by coloured
 * arrows.
 */
const ApplicationFlow = ({ primary, items }) => {
  const { arrow } = primary
  return (
    <section className={styles.process}>
      <div className="row">
        <div className="columns large-12">
          <ul className={styles.steps}>
            {items.map(item => {
              return (
                <li className={styles.step} key={item.title.text}>
                  <Img
                    fixed={item.icon.fixed}
                    alt={item.icon.alt}
                    aria-label={item.icon_label}
                    role="img"
                  />
                  <p className={styles.title} style={{ color: item.color }}>
                    {item.title.text}
                  </p>
                  <p>{item.subtitle.text}</p>
                  <svg
                    className={styles.arrow}
                    alt={arrow.alt}
                    role="presentation"
                  >
                    {/* Use color filter to dynamically set arrow colors */}
                    <ColorizeFilter
                      id={`color-${item.color}`}
                      dark={item.color}
                    />
                    <image
                      height="67px"
                      xlinkHref={arrow.url}
                      style={{ filter: `url(#color-${item.color})` }}
                    />
                  </svg>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default ApplicationFlow
