import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import About from "../components/About"
import ApplicationFlow from "../components/ApplicationFlow"
import Grants from "../components/Grants"
import LinkCTA from "../components/LinkCTA"
import Hero from "../components/Hero"
import CTABanner from "../components/CTABanner"
import SocialButtons from "../components/SocialButtons"
import Contact from "../components/Contact"
import ReferringPartner from "../components/ReferringPartner"
import EmailModal from "../components/EmailModal"

const IndexPage = ({ data }) => {
  const sliceComponents = {
    PrismicHomeBodyAbout: About,
    PrismicHomeBodyApplicationFlow: ApplicationFlow,
    PrismicHomeBodyGrants: Grants,
    PrismicHomeBodyLinkCta: LinkCTA,
    PrismicHomeBodyFullWidthCtaBanner: CTABanner,
    PrismicHomeBodySocialButtons: SocialButtons,
    PrismicHomeBodyContact: Contact,
    PrismicHomeBodyReferringPartner: ReferringPartner,
    PrismicHomeBodyHero: Hero,
    PrismicHomeBodyEmailModal: EmailModal,
  }
  const homeData = data.allPrismicHome.edges[0].node.data
  return (
    <Layout>
      <SEO
        title={homeData.title.text}
        description={homeData.meta_description.text}
        image={homeData.meta_image.url}
      />
      {homeData.body.map(({ __typename, ...props }, i) => {
        if (sliceComponents[__typename])
          return React.createElement(sliceComponents[__typename], {
            ...props,
            // eslint-disable-next-line react/no-array-index-key
            key: `${__typename}-${i}`,
          })
        return <p key={__typename}>TODO: {__typename}</p>
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicHome {
      edges {
        node {
          prismicId
          id
          data {
            title {
              text
            }
            meta_image {
              url
            }
            meta_description {
              text
            }
            body {
              __typename
              ... on PrismicHomeBodyHero {
                primary {
                  above_title {
                    text
                  }
                  above_subtitle {
                    text
                  }
                  logo {
                    fluid(maxWidth: 300) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  logo_label
                  alt_logo {
                    fluid(maxWidth: 300) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  alt_logo_label
                  alt_trigger
                  below_title {
                    text
                  }
                  below_subtitle {
                    text
                  }
                  size
                  subtitle_size
                }
                items {
                  image {
                    fluid(maxWidth: 2000) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  image_label
                }
              }
              ... on PrismicHomeBodyApplicationFlow {
                id
                primary {
                  arrow {
                    url
                    alt
                  }
                }
                items {
                  color
                  icon_label
                  subtitle {
                    text
                  }
                  title {
                    text
                  }
                  icon {
                    fixed(width: 152) {
                      ...GatsbyPrismicImageFixed
                    }
                    alt
                  }
                }
              }
              ... on PrismicHomeBodyAbout {
                id
                primary {
                  description {
                    html
                  }
                  title {
                    text
                  }
                }
              }
              ... on PrismicHomeBodyGrants {
                id
                items {
                  amount
                  action {
                    url
                  }
                  button_text {
                    text
                  }
                  color
                  heading {
                    text
                  }
                  description {
                    html
                  }
                  level_enabled
                }
                primary {
                  heading {
                    text
                  }
                  notice {
                    html
                  }
                  subheading {
                    text
                  }
                  disabled_button_text
                  error_notification_signup_text
                  mailchimp_notification_form_url {
                    url
                  }
                  notification_popup_content {
                    html
                  }
                  notification_popup_button_text
                  notification_popup_title {
                    text
                  }
                  secondary_notice {
                    html
                    text
                  }
                  submitting_notification_signup_text
                  successful_notification_signup_text
                }
              }
              ... on PrismicHomeBodyLinkCta {
                id
                primary {
                  cta {
                    html
                  }
                }
                items {
                  logo_image {
                    fluid(maxWidth: 500) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  logo_label
                  link {
                    url
                  }
                }
              }
              ... on PrismicHomeBodyFullWidthCtaBanner {
                id
                primary {
                  color
                  image_side
                  image {
                    fluid(maxWidth: 220) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  image_label
                  description {
                    html
                  }
                  call_to_action {
                    text
                  }
                }
                items {
                  button_action {
                    url
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicHomeBodySocialButtons {
                id
                primary {
                  above {
                    html
                  }
                  below {
                    html
                  }
                }
                items {
                  logo
                  logo_label
                  color
                  link {
                    url
                  }
                }
              }
              ... on PrismicHomeBodyContact {
                id
                primary {
                  action {
                    url
                  }
                  description {
                    html
                  }
                  heading {
                    text
                  }
                }
              }
              ... on PrismicHomeBodyReferringPartner {
                primary {
                  lead_text {
                    text
                  }
                }
              }
              ... on PrismicHomeBodyEmailModal {
                primary {
                  modal_title {
                    text
                  }
                  modal_subtitle {
                    html
                  }
                  form_action_url {
                    url
                  }
                  modal_submit_text
                  email_field_name
                  error_message
                  success_message
                  submitting_message
                }
              }
              
            }
          }
        }
      }
    }
  }
`

export default withPreview(IndexPage)