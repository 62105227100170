const useDomainMatch = domain => {
  const domains = ["secret"]

  if (domain) {
    if (domains.includes(domain?.toLowerCase())) {
      return true
    }
  }
  return false
}

export default useDomainMatch
