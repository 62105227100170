import React, { useRef } from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./hero.module.scss"
import Carousel from "../Carousel"
import useWindowResize from "../../hooks/use-window-resize"

const Title = ({ title, subtitle }) => (
  <>
    <h1>{title.text}</h1>
    {subtitle.html ? (
      <div
        className={styles.htmlSubtitle}
        dangerouslySetInnerHTML={{ __html: subtitle.html }}
      />
    ) : (
      <p>{subtitle.text}</p>
    )}
  </>
)

/**
 * Full-width hero with carousel of background images and text above/below logo
 */
const Hero = ({ primary, items }) => {
  const {
    above_title,
    above_subtitle,
    logo,
    logo_label,
    alt_logo,
    alt_logo_label,
    alt_trigger,
    below_title,
    below_subtitle,
    size,
    subtitle_size,
  } = primary

  const logotype = useRef()
  const section = useRef()

  let showAltLogo = false
  if (typeof window !== "undefined") {
    if (new URLSearchParams(window.location.search).get(alt_trigger) !== null) {
      showAltLogo = true
    }
  }

  // flexbox polyfill
  // vertical align logotype
  useWindowResize(() => {
    if (section.current && logotype.current) {
      const hContainer = section.current.clientHeight
      const hLogo = logotype.current.clientHeight
      const offset = (hContainer - hLogo) / 2
      const top = `${offset}px`
      logotype.current.style.top = top
    }
  })

  return (
    <section
      className={cx(
        styles.hero,
        size && "big",
        showAltLogo && styles.alt,
        subtitle_size && "big-subtitle"
      )}
      ref={section}
    >
      <div className="row" style={{ maxWidth: "none" }}>
        <Carousel slides={items} />
        <div ref={logotype} className={styles.logotype}>
          {showAltLogo && alt_logo.fluid && (
            <Img
              className={styles.image}
              fluid={alt_logo.fluid}
              alt={alt_logo.alt}
              aria-label={alt_logo_label}
              role="img"
              loading="eager"
            />
          )}
          <Title title={above_title} subtitle={above_subtitle} />
          {!showAltLogo && (
            <>
              {logo.fluid && (
                <Img
                  className={styles.image}
                  fluid={logo.fluid}
                  alt={logo.alt}
                  aria-label={logo_label}
                  role="img"
                  loading="eager"
                />
              )}
              <Title title={below_title} subtitle={below_subtitle} />
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero