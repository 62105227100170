/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "../About/about.module.scss"
import useReferrerCookie from "../../hooks/use-referrer-cookie"

const ReferringPartner = ({ primary }) => {
  const [showMessage, setShowMessage] = useState(false)
  const [cookieMessage, setCookieMessage] = useState(
    useReferrerCookie("hostrefer")
  )
  const [storageMessage, setStorageMessage] = useState(
    (typeof window === "object" &&
      window.localStorage.getItem("refer_shown")) ||
      ""
  )

  useEffect(() => {
    if (typeof window === "object") {
      if (storageMessage !== cookieMessage && cookieMessage) {
        fetch(
          `https://research.tigweb.org/airtable/ry-subdomains.html?Subdomain=${cookieMessage}`,
          { mode: "no-cors" }
        )
        setShowMessage(true)
        window.localStorage.setItem("refer_shown", cookieMessage)
      }
      // else {
      //   // uh we gotta get subdomain somehow

      // }
    }
  }, [])

  if (showMessage) {
    return (
      <section className={styles.about}>
        <div className="row">
          <div className="columns large-12">
            <h2>
              {primary.lead_text.text} {cookieMessage}
            </h2>
          </div>
        </div>
      </section>
    )
  }
  return <></>
}

export default ReferringPartner
