/* eslint-disable react/no-danger */
import React, { useRef, useState } from "react"
import cx from "classnames"
import styles from "./grants.module.scss"
import Link from "../Link"
import useWindowResize from "../../hooks/use-window-resize"
import useReferrerCookie from "../../hooks/use-referrer-cookie"
import NotificationEmailModal from "../NotificationEmailModal"
import useCookieLinkQueryParam from "../../hooks/use-cookie-link-query-param"
import useDomainMatch from "../../hooks/use-domain-match"

/**
 * Display grant levels, given heading, subheading, notice, and items
 */
const Grants = ({ primary, items }) => {
  const {
    heading,
    subheading,
    notice,
    disabled_button_text,
    secondary_notice,
  } = primary
  const itemsRef = useRef()
  const [heightOverride, setHeightOverride] = useState(undefined)
  const [
    notificationEmailModalIsOpen,
    setNotificationEmailModalIsOpen,
  ] = useState(false)

  // flexbox polyfill
  // find maximum height and set all <div>s to that height
  useWindowResize(() => {
    if (itemsRef.current?.children?.length > 0) {
      const children = Array.from(
        itemsRef.current.getElementsByClassName(styles.detail)
      )
      const maxHeight = Math.max(
        ...children.map(elem =>
          // sum heights of children of <div> (allow for multiple <p>s)
          Array.from(elem.children).reduce(
            (total, child) => total + child.scrollHeight,
            0
          )
        )
      )
      setHeightOverride(`${maxHeight}px`)
    }
  })

  const closeModal = () => {
    setNotificationEmailModalIsOpen(false)
  }

  const openModal = () => {
    setNotificationEmailModalIsOpen(true)
  }

  const updateLinks = html => {
    const regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/gi
    let link

    // eslint-disable-next-line no-cond-assign
    while ((link = regex.exec(html)) !== null) {
      // eslint-disable-next-line no-param-reassign
      html = html.replace(
        link[2],
        useCookieLinkQueryParam(link[2], ["hostreferSubdomain"])
      )
    }

    return html
  }

  return (
    <section id="grants" className={styles.grants}>
      <NotificationEmailModal
        primary={primary}
        modalOpen={notificationEmailModalIsOpen}
        closeModal={closeModal}
      />
      <div className="row">
        <div className="large-12 columns">
          <h3>{heading.text}</h3>
          <p className={styles.subheading}>{subheading.text}</p>
          {typeof window !== "undefined" && (
            <div
              className="callout alert round"
              // replaces the links in the html with the updated ones for
              // hostreferSubdomain search params
              dangerouslySetInnerHTML={{ __html: updateLinks(notice.html) }}
            />
          )}
        </div>
      </div>
      <div className="row" ref={itemsRef}>
        {items.map(
          ({
            color,
            heading: money,
            amount,
            description,
            action,
            button_text,
            level_enabled,
          }) => (
            <div
              className={cx("columns medium-4 small-12", styles.item)}
              key={money.text}
            >
              <h4 style={{ background: color }}>{money.text}</h4>
              <div
                className={styles.detail}
                style={{ height: heightOverride }}
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
              <div className={styles.footer}>
                {/* Only enable if the referrer is whitelisted or if the level is enabled */}
                {useDomainMatch(useReferrerCookie("hostreferSubdomain")) ||
                level_enabled ? (
                  <Link
                    to={`${action.url}?amount=${amount}`}
                    className={cx("expand button", styles.button)}
                  >
                    {button_text?.text}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className={cx("expand button", styles.button)}
                    onClick={openModal}
                  >
                    {disabled_button_text}
                  </button>
                )}
              </div>
            </div>
          )
        )}
        {secondary_notice?.text && typeof window !== "undefined" && (
          <div className="large-12 columns">
            <div
              className="callout info round"
              // replaces the links in the html with the updated ones for
              // hostreferSubdomain search params
              dangerouslySetInnerHTML={{
                __html: updateLinks(secondary_notice.html),
              }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Grants
