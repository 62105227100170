import React, { useState, useEffect, useRef } from "react"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./carousel.module.scss"

let frame = 0

/**
 * Fades between images on a set interval.
 * IE-compatible using polyfilling.
 */
const Carousel = ({ slides }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const slideRef = useRef()
  const needPolyfill = !(
    typeof window === "object" &&
    window.CSS &&
    window.CSS.supports("transition", "all 1s")
  )

  useEffect(() => {
    // for IE
    let ieCurrentImage = 0
    let ieLastImage = 0

    const carouselInt = setInterval(() => {
      setCurrentImage(prevImage => (prevImage + 1) % slides.length)

      if (needPolyfill) {
        // advance image state
        ieLastImage = ieCurrentImage
        ieCurrentImage = (ieLastImage + 1) % slides.length
        frame = 0
        // fade between both images
        const frameInt = setInterval(() => {
          if (frame === 250) {
            clearInterval(frameInt)
          } else {
            frame += 1
            const current = slideRef.current.children[ieCurrentImage]
            const last = slideRef.current.children[ieLastImage]
            last.style.opacity = 1 - frame / 250
            current.style.opacity = frame / 250
          }
        }, 10)
      }
    }, 5000)
    return () => clearInterval(carouselInt)
  }, [slides])

  return (
    <div className={styles.container} ref={slideRef}>
      {slides.map((slide, i) => {
        return (
          <div
            className={styles.slide}
            key={slide.image_label}
            style={needPolyfill ? {} : { opacity: currentImage === i ? 1 : 0 }}
            aria-hidden={currentImage !== i}
          >
            <Img
              fluid={slide.image.fluid}
              alt={slide.image.alt}
              role="img"
              aria-label={slide.image_label}
              objectFit="cover"
              // eager load first carousel image
              loading={i === 0 ? "eager" : "lazy"}
              fadeIn={i === 0}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Carousel
