/**
 * Grabs the value of a given cookie
 * @param {string} cookieName name of cookie to return value for
 */
const useReferrerCookie = cookieName => {
  if (typeof window !== "undefined") {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length).replace(/\+/g, " ")
      }
    }
    return false
  }
  return false
}

export default useReferrerCookie
