import React from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./linkcta.module.scss"
import Link from "../Link"

const LinkCTA = ({ primary, items }) => {
  return (
    <section className={styles.cta}>
      <div className="row">
        <div
          className={cx("columns large-12", styles.ctaLink)}
          dangerouslySetInnerHTML={{ __html: primary.cta.html }}
        />
      </div>
      <div className="row">
        {items.map(item => (
          <div className="columns small-12 medium-6 small-centered" key={item.logo_label}>
            <Link to={item.link.url}>
              <Img
                fluid={item.logo_image.fluid}
                alt={item.logo_image.alt}
                aria-label={item.logo_label}
                role="img"
              />
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}

export default LinkCTA
