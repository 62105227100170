/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react"
import { FaTimes } from "react-icons/fa"
import cx from "classnames"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styles from "./notificationemailmodal.module.scss"

const NotificationEmailModal = ({ primary, modalOpen, closeModal }) => {
  const [email, setEmail] = useState()

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  const submitForm = subscribe => {
    const formData = {}
    formData[primary?.notificaton_email_field_name || "EMAIL"] = email
    subscribe(formData)
  }

  if (modalOpen) {
    return (
      <>
        <div className={styles.overlay} onClick={closeModal} />
        <div className={styles.modalContainer}>
          <button
            onClick={closeModal}
            data-message="Close Popup"
            aria-label="Close Popup"
            className={styles.closeButton}
            type="button"
          >
            <FaTimes />
          </button>
          <h3>{primary?.notification_popup_title?.text}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: primary?.notification_popup_content?.html,
            }}
          />
          <MailchimpSubscribe
            url={primary?.mailchimp_notification_form_url?.url}
            render={({ subscribe, status, message }) => (
              <form noValidate>
                <input
                  type="email"
                  required
                  name="notification email field"
                  placeholder="you@takingitglobal.org"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button
                  className={cx("button primary expanded", styles.submitButton)}
                  aria-label="Submit email"
                  onClick={() => submitForm(subscribe)}
                  type="button"
                >
                  {primary?.notification_popup_button_text}
                </button>
                <div className={styles.status}>
                  {status === "sending" &&
                    primary?.submitting_notification_signup_text}
                  {status === "success" &&
                    primary?.successful_notification_signup_text}
                  {status === "error" &&
                    `${primary?.error_notification_signup_text}: ${message}`}
                </div>
              </form>
            )}
          />
        </div>
      </>
    )
  }

  return null
}

export default NotificationEmailModal
