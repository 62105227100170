import React from "react"
import styles from "./contact.module.scss"

/**
 * Contact form POSTing to a given URL from Prismic.
 */
const Contact = ({ primary }) => {
  const { heading, description, action } = primary
  const isEn = process.env.GATSBY_BUILD_LANG !== "fr"

  return (
    <section className={styles.contact} id="contactus">
      <div className="row">
        <div className="small-12 medium-6 large-4 small-centered columns">
          <form
            action={action.url}
            encType="multipart/form-data"
            method="POST"
            acceptCharset="UTF8"
            autoComplete="off"
            id="form45"
            name="form45"
          >
            <h3>{heading.text}</h3>
            <input
              placeholder={isEn ? "Name" : "Nom"}
              name="Field1"
              autoComplete="off"
              aria-label={isEn ? "Name field" : "Champ pour le nom"}
              type="text"
              required
            />
            <input
              placeholder={isEn ? "Email" : "Courriel"}
              name="Field9"
              autoComplete="off"
              aria-label={isEn ? "Email field" : "Champ pour le courriel"}
              type="email"
              required
            />
            <input
              placeholder={isEn ? "Phone Number" : "Numéro de téléphone"}
              name="Field7"
              autoComplete="off"
              aria-label={
                isEn
                  ? "Phone number field"
                  : "Champ pour le numéro de téléphone"
              }
              type="tel"
            />
            <textarea
              placeholder={isEn ? "Your message" : "Ton message"}
              aria-label={isEn ? "Message field" : "Champ pour le message"}
              name="Field5"
              rows="5"
              required
            />
            <input
              type="submit"
              value={isEn ? "Submit" : "Soumettre"}
              className="large expanded button"
            />
            <input
              type="hidden"
              name="idstamp"
              value="yI61YMq/spzEBCOaUETDxdqGOWm35RjQijUXI8BbJWM="
            />
          </form>
        </div>
      </div>
      <div className="row">
        <div
          className="small-12 columns"
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
      </div>
    </section>
  )
}

export default Contact
