/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { FaTimes } from "react-icons/fa"
import cx from "classnames"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styles from "./emailmodal.module.scss"

const EmailModal = ({ primary }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [email, setEmail] = useState()

  const closeModal = () => setModalVisible(false)

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  const submitForm = subscribe => {
    const formData = {}
    formData[primary.email_field_name] = email
    subscribe(formData)
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const timer = setTimeout(() => {
      setModalVisible(true)
    }, 16000)
  }, [])

  if (modalVisible) {
    return (
      <>
        <div className={styles.overlay} onClick={closeModal} />
        <div className={styles.modalContainer}>
          <button
            onClick={closeModal}
            data-message="Close Popup"
            aria-label="Close Popup"
            className={styles.closeButton}
            type="button"
          >
            <FaTimes />
          </button>
          <h3>{primary.modal_title.text}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: primary.modal_subtitle.html }}
          />
          <MailchimpSubscribe
            url={primary.form_action_url.url}
            render={({ subscribe, status, message }) => (
              <form noValidate>
                <input
                  type="email"
                  required
                  name={primary.email_field_name}
                  placeholder="you@takingitglobal.org"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button
                  className={cx("button primary expanded", styles.submitButton)}
                  aria-label="Submit email"
                  onClick={() => submitForm(subscribe)}
                  type="button"
                >
                  {primary.modal_submit_text}
                </button>
                <div className={styles.status}>
                  {status === "sending" && primary.submitting_message}
                  {status === "success" && primary.success_message}
                  {status === "error" && `${primary.error_message}: ${message}`}
                </div>
              </form>
            )}
          />
        </div>
      </>
    )
  }

  return null
}

export default EmailModal
