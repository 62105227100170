import React from "react"
import styles from "./about.module.scss"

/**
 * Renders title text and description.
 */
const About = ({ primary }) => {
  return (
    <section className={styles.about}>
      <div className="row">
        <div className="columns large-12">
          <h2>{primary.title.text}</h2>
          <div dangerouslySetInnerHTML={{ __html: primary.description.html }} />
        </div>
      </div>
    </section>
  )
}

export default About
